import type { ProductType } from '@/models';
import type {
  AdditionalProduct,
  DeliveryWeek,
  LoyaltyLevel,
  Recipe,
} from '@ruokaboksi/api-client';
import {
  MIN_LOYALTY_LEVEL,
  Price,
  Week,
  ZERO_PRICE,
  calculateBoxPrice,
  calculateDeliveryPlanPrice,
  makeStartOfDayUTC,
} from '@ruokaboksi/utils';
import { acceptHMRUpdate, defineStore } from 'pinia';

/**
 * Store for managing selected products.
 * @example
 * ```ts
 * setup() {
 *   const selectProductsStore = useSelectProductsStore();
 *   const {
 *     ...helpers,
 *     setProductType // Required
 *   } = selectProductsStore;
 *
 *   const {
 *     ...variables
 *   } = storeToRefs(selectProductsStore);
 *
 *   onBeforeMount(() => {
 *     setProductType('recipe'); // or 'additional-product'
 *   });
 * }
 * ```
 * */
const useSelectProductsStore = defineStore('SelectProductsStore', () => {
  const hasProductSelectionChanged = ref(false);
  const isSaving = ref<boolean>(false);
  const products = ref<AdditionalProduct[] | Recipe[]>([]);
  const productType = ref<ProductType | null>(null);
  const selectedProductIds = ref<string[]>([]);

  const { currentCustomer } = useCurrentCustomer();
  const { subscriptionId, subscription } = useCustomerSubscriptions();
  const { setWeekProducts } = useDeliveriesApi();
  const {
    boxType,
    isLoadingDeliveryWeeks,
    maxRecipes,
    minRecipes,
    priceTiers,
    selectedDeliveryWeek,
    selectedDeliveryWeekCredit,
    selectedFullDeliveryWeek,
  } = useDeliveryWeeks();
  const { getAvailableDeliverySlots } = useDeliveriesApi();
  const noticeStore = useNoticeStore();
  const { isLoadingWeekConfigurations, weekConfiguration } =
    useWeekConfigurations();
  const { market } = useCurrentMarket();
  const { t } = useI18n();

  const isFinnishMarket = computed(() => market.value === 'FIN');

  const { data: deliverySlots } = getAvailableDeliverySlots(subscription);

  const router = useRouter();

  const isLoading = computed<boolean>(
    () => isLoadingDeliveryWeeks.value || isLoadingWeekConfigurations.value
  );

  const isAdditionalProduct = computed<boolean>(
    () => productType.value === 'additional-product'
  );

  const isRecipe = computed<boolean>(() => productType.value === 'recipe');

  const initialAdditionalProducts = computed(() => {
    const products = selectedFullDeliveryWeek.value?.additionalProducts || [];
    return products.reduce<string[]>((acc, item) => {
      return [...acc, ...new Array(item.quantity || 0).fill(item.id)];
    }, []);
  });

  /** List of additional product IDs for selected delivery week. */
  const additionalProductIds = computed<
    DeliveryWeek['additionalProducts'] | string[]
  >(() => {
    if (isAdditionalProduct.value) {
      return selectedProductIds.value;
    } else if (selectedFullDeliveryWeek.value?.additionalProducts.length) {
      return selectedFullDeliveryWeek.value?.additionalProducts;
    } else {
      return [];
    }
  });

  /** Total price for selected additional products. */
  const additionalProductsTotal = computed<number>(() => {
    let cents = 0;

    if (isAdditionalProduct.value) {
      cents += selectedProductIds.value.reduce((sum, id) => {
        const frozenProduct =
          selectedFullDeliveryWeek.value?.additionalProducts.find(
            (item) => item.id === id
          );

        if (frozenProduct) return sum + frozenProduct.price.grossPrice;

        const product = weekConfiguration.value.additionalProducts.find(
          (item) => item.id === id
        );

        if (product && product.price) return sum + product.price;

        return 0;
      }, 0);
    } else {
      cents +=
        selectedFullDeliveryWeek.value?.additionalProducts.reduce(
          (sum, item) => sum + item.price.grossPrice,
          0
        ) || 0;
    }

    return cents;
  });

  /** List of recipe IDs for selected delivery week. */
  const recipeIds = computed<DeliveryWeek['recipes'] | string[]>(() => {
    if (isRecipe.value && selectedProductIds.value?.length) {
      return selectedProductIds.value;
    } else if (selectedFullDeliveryWeek.value?.recipes?.length) {
      return selectedFullDeliveryWeek.value?.recipes;
    } else {
      return [];
    }
  });

  const additionalRecipePriceDifference = computed<number>(() => {
    const activeCampaign =
      selectedFullDeliveryWeek.value?.activeCampaign ?? null;
    if (!priceTiers.value.length || !subscription.value) {
      return 0;
    }

    const now = Week.fromPaymentDate(
      makeStartOfDayUTC(selectedDeliveryWeek.value?.paymentDate),
      subscription.value.defaultDeliverySlot
    );
    const priceTier1 = calculateBoxPrice({
      priceTier: priceTiers.value[0],
      campaign: activeCampaign,
      loyaltyLevel: currentCustomer.value?.loyaltyLevel ?? MIN_LOYALTY_LEVEL,
      now,
    });

    const priceTier2 = calculateBoxPrice({
      priceTier: priceTiers.value[1],
      campaign: activeCampaign,
      loyaltyLevel: currentCustomer.value?.loyaltyLevel ?? MIN_LOYALTY_LEVEL,
      now,
    });

    const priceDiff = priceTier1.netPrice - priceTier2.netPrice;

    return Math.abs(priceDiff);
  });

  const totalPrice = computed<Price>(() => {
    if (
      selectedFullDeliveryWeek.value &&
      !selectedFullDeliveryWeek.value.editable
    ) {
      return new Price(selectedFullDeliveryWeek.value.totalPrice);
    }

    const priceTier = priceTiers.value.find(
      (item) => item.recipeCount === recipeIds.value.length
    );

    if (!priceTier || !subscription.value || !selectedFullDeliveryWeek.value)
      return ZERO_PRICE;

    const currentlySelectedProducts = selectedProductIds.value
      .map((product) => products.value.find((item) => item.id === product))
      .filter((product) => !!product) as AdditionalProduct[] | Recipe[];

    const additionalProducts = isAdditionalProduct.value
      ? currentlySelectedProducts
      : selectedFullDeliveryWeek.value.additionalProducts;

    const deliverySlot =
      deliverySlots.value?.find(
        (slot) => slot.id === selectedFullDeliveryWeek.value?.deliverySlotId
      ) ?? subscription.value.defaultDeliverySlot;

    return calculateDeliveryPlanPrice({
      priceTier,
      recipes: isRecipe.value
        ? currentlySelectedProducts
        : selectedFullDeliveryWeek.value.recipes,
      additionals: additionalProducts.map((product) => {
        return {
          id: product.id,
          frozenProductPrice:
            'price' in product
              ? typeof product.price === 'object'
                ? product.price.grossPrice
                : product.price
              : 0,
          quantity: 1,
        };
      }),
      campaign: selectedFullDeliveryWeek.value?.activeCampaign || null,
      loyaltyLevel:
        (currentCustomer.value?.loyaltyLevel as LoyaltyLevel) ??
        MIN_LOYALTY_LEVEL,
      deliverySlot,
      now: Week.fromPaymentDate(
        new Date(selectedFullDeliveryWeek.value?.paymentDate),
        deliverySlot
      ),
      credit: selectedDeliveryWeekCredit.value,
    });
  });

  /** Total price before discounts in cents. */
  const totalPreDiscounts = computed<number>(() => totalPrice.value.grossPrice);

  /** @returns `true` if discounted total differs from total price. */
  const hasDiscount = computed<boolean>(() =>
    totalPrice.value.hasPriceModifier()
  );

  /**
   * @returns `true` If selected recipes are within range of
   * min and max values.
   *
   * Always `true` for additional products.
   */
  const validSelection = computed<boolean>(() =>
    isRecipe.value
      ? selectedProductIds.value.length <= maxRecipes.value &&
        selectedProductIds.value.length >= minRecipes.value
      : true
  );

  /**
   * Initializes and sorts selected products based on
   * current product type and selected delivery week.
   */
  const initializeSelectedProducts = (): void => {
    selectedProductIds.value = isRecipe.value
      ? (selectedFullDeliveryWeek.value?.recipes?.map((item) => item.id) ?? [])
      : (selectedFullDeliveryWeek.value?.additionalProducts?.flatMap((item) =>
          Array(item.quantity || 1).fill(item.id)
        ) ?? []);
    setHasProductSelectionChanged(false);
    sortProducts();
  };

  /** Adds a product ID to the current selection. */
  const selectProduct = (productId: string): void => {
    selectedProductIds.value.push(productId);
    setHasProductSelectionChanged(true);
  };

  const setHasProductSelectionChanged = (value: boolean): void => {
    hasProductSelectionChanged.value = !!value;
  };

  /** Sets the saving state for the store. */
  const setIsSaving = (value: boolean): void => {
    isSaving.value = value;
  };

  /** Sets the current product type for the store. */
  const setProductType = (value: ProductType | null): void => {
    productType.value = value;
    initializeSelectedProducts();
  };

  /** Removes a product from the current selection. */
  const unselectProduct = (productId: string): void => {
    selectedProductIds.value.splice(
      selectedProductIds.value.indexOf(productId),
      1
    );
    setHasProductSelectionChanged(true);
  };

  /**
   * Change the order of the products:
   * first the initially selected products
   */
  const sortProducts = (): void => {
    if (isRecipe.value) {
      const availableRecipes = weekConfiguration.value.recipes.filter(
        (recipe) => boxType.value?.size === recipe.size
      );

      const selectedRecipes: Recipe[] = selectedProductIds.value.map(
        (id) =>
          availableRecipes.find((recipes) => recipes?.id === id) ?? {
            ...fallbackRecipe,
            title: t(fallbackRecipe.title),
            id,
            sku: id,
          }
      );

      const unselectedRecipes = availableRecipes.filter(
        (availableRecipe) => !selectedRecipes.includes(availableRecipe)
      );

      products.value = [...selectedRecipes, ...unselectedRecipes];
    } else {
      const availableProducts = weekConfiguration.value.additionalProducts;

      const selectedProducts: AdditionalProduct[] =
        selectedProductIds.value.map(
          (id) =>
            availableProducts.find((product) => product?.id === id) ?? {
              ...fallbackAdditionalProduct,
              title: t(fallbackAdditionalProduct.title),
              id,
              price:
                selectedFullDeliveryWeek.value?.additionalProducts.find(
                  (product) => product?.id === id
                )?.price.grossPrice ?? 0,
              sku: id,
            }
        );

      const unselectedProducts = availableProducts.filter(
        (available) => !selectedProducts.includes(available)
      );
      products.value = [...selectedProducts, ...unselectedProducts];
    }
  };

  /** Saves product changes and shows a notice when done. */
  /** Saves product changes and shows a notice when done. */
  const saveProductChanges = async (): Promise<void> => {
    if (!subscriptionId.value || !selectedFullDeliveryWeek.value) return;

    const recipeIds = isRecipe.value
      ? selectedProductIds.value
      : selectedFullDeliveryWeek.value.recipes?.map((i) => i.id) || [];

    const additionalProductIds = isAdditionalProduct.value
      ? selectedProductIds.value
      : selectedFullDeliveryWeek.value.additionalProducts?.flatMap((item) =>
          Array(item.quantity || 1).fill(item.id)
        ) || [];

    const productIds = {
      recipes: recipeIds,
      additionalProducts: additionalProductIds,
    };

    setIsSaving(true);
    setHasProductSelectionChanged(false);

    try {
      await setWeekProducts(
        productIds,
        subscriptionId.value,
        selectedFullDeliveryWeek.value?.paymentDate
      );

      setIsSaving(false);
      setHasProductSelectionChanged(false);
      noticeStore.addNotice({
        text: t('select_products.delivery_updated'),
        type: 'success',
      });

      if (
        recipeIds.length > minRecipes.value &&
        recipeIds.length > selectedFullDeliveryWeek.value.recipes.length
      ) {
        postHogSendCustomEvent('Extra Recipe Added');
      }
      postHogSendCustomEvent('Delivery Updated', {
        numberOfRecipes: recipeIds.length,
        numberOfAdditionalProducts: additionalProductIds.length,
        orderValue: totalPrice.value.grossPrice,
      });

      if (
        router.currentRoute.value.path !== '/additional-products' &&
        isFinnishMarket.value
      ) {
        router.push({
          path: '/additional-products',
          query: {
            selectedWeek: selectedDeliveryWeek.value?.weekString,
            redirectedFrom: 'recipes',
          },
        });
      } else {
        router.replace({
          query: {
            ...router.currentRoute.value.query,
            redirectedFrom: undefined,
          },
        });
        sortProducts();
      }
    } catch (_error) {
      noticeStore.addNotice({
        text: 'Toimituksen päivitys epäonnistui. Mikäli virhe toistuu, lataa sivu uudestaan.',
        type: 'caution',
      });
    }
  };

  initializeSelectedProducts();

  watch([selectedFullDeliveryWeek], initializeSelectedProducts);
  watch([weekConfiguration, boxType], sortProducts);

  return {
    additionalProductIds,
    additionalProductsTotal,
    additionalRecipePriceDifference,
    hasDiscount,
    hasProductSelectionChanged,
    initializeSelectedProducts,
    isAdditionalProduct,
    isLoading,
    isSaving,
    isRecipe,
    productType,
    products,
    recipeIds,
    saveProductChanges,
    selectProduct,
    selectedProductIds,
    setProductType,
    totalPreDiscounts,
    totalPrice,
    unselectProduct,
    validSelection,
    initialAdditionalProducts,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSelectProductsStore, import.meta.hot)
  );
}

export default useSelectProductsStore;
